<script>
  import Header from "./Header.svelte";
</script>

<style>
  section {
    padding: 1em 0;
    border-bottom: 1px solid var(--primary);
  }
  .success {
    max-width: var(--max-width);
    margin: 0 auto;
    text-align: center;
  }
  h1 {
    font-size: 3em;
  }
  p {
    padding: 0 0.5em;
    text-align: left;
    font-size: 2em;
  }
  button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1em;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    opacity: 1;
  }
  button:hover {
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    opacity: 0.5;
  }
</style>

<Header position={'relative'} background={'var(--primary)'} shadow={'none'} />
<section>
  <div class="success">
    <h1>404 - Page Not Found</h1>
    <p>The page you were trying to reach does not exist.</p>
    <p>
      You can check out our
      <button
        on:click={() => {
          window.location.href = '/#cows';
        }}>
        cows here
      </button>
      or maybe you would like to take a peak
      <button
        on:click={() => {
          window.location.href = '/#hens';
        }}>
        at the chickens?
      </button>
    </p>

  </div>
</section>

<script>
  import Header from "./Header.svelte";
  import cows from "./cows.js";

  export let params;

  //filter cows down to cow matching the params.id which is the ID in the URL
  const cow = cows.filter((result) => result.id === params.id);

  //refresh page after subCow.id is updated
  let subCow;
  function refresh() {
    setTimeout(() => {
      location.reload();
    }, 200);
  }

  let highlightPath = false;
  // $: highlightPath = $params;
</script>

<Header position={"relative"} background={"var(--primary)"} shadow={"none"} />
<section>
  <div class="cow">
    <h1 id="name">
      {cow[0].name}
      <a
        href="http://adca.pedigree-db.com/pedigree.cgi?horse={cow[0]
          .regNum}&OwnerName=12083&SearchFor=1&Page=1&Sort=0"
        target="_blank"
      >
        ADCA Site
      </a>
    </h1>

    <ul>
      {#each cows as subCow}
        <li>
          <button
            on:click={() => {
              window.location.href = "/cow/" + subCow.id;
            }}
          >
            {subCow.farm}
            {subCow.name}
          </button>
        </li>
      {/each}
    </ul>

    <div class="flex">
      <div>
        <label for="regNum">ADCA Reg#:</label>
        <span id="regNum">{cow[0].regNum}</span>
      </div>
      <div>
        <label for="volume">Volume:</label>
        <span id="volume">{cow[0].volume}</span>
      </div>
      <div>
        <label for="Gender">Gender:</label>
        <span id="Gender">{cow[0].gender}</span>
      </div>
      <div>
        <label for="Polled">Polled:</label>
        <span id="Polled">{cow[0].polled}</span>
      </div>
      <div>
        <label for="Color">Color:</label>
        <span id="Color">{cow[0].color}</span>
      </div>
      <div>
        <label for="Chondrodysplasia">Chondrodysplasia:</label>
        <span id="Chondrodysplasia">{cow[0].chon}</span>
      </div>
      <div>
        <label for="PHA">PHA:</label>
        <span id="PHA">{cow[0].pha}</span>
      </div>
      <div>
        <label for="Parentage DNA">Parentage DNA:</label>
        <span id="Parentage DNA">{cow[0].dna}</span>
      </div>
      <div>
        <label for="Lab">Lab:</label>
        <span id="Lab">{cow[0].lab}</span>
      </div>
      <div>
        <label for="Date of Birth">Date of Birth:</label>
        <span id="Date of Birth">{cow[0].dob}</span>
      </div>
    </div>
    <!-- <img src={cow[0].path} alt="{cow[0].name} {cow[0].cowAlt}" /> -->
    <div class="wrapper" style="min-height: 100px; width: 100%;">
      <div class="loaded" style="position: relative; width: 100%;">
        <div style="position: relative; overflow: hidden;">
          <picture>
            <source
              type="image/webp"
              srcset="../{cow[0].path}-400.webp 375w, ../{cow[0]
                .path}-800.webp
              768w, ../{cow[0]
                .path}-1200.webp 1024w"
              sizes="(max-width: 1000px) 100vw, 1000px"
            />
            <source
              srcset="../{cow[0].path}-400{cow[0].ext} 375w, ../{cow[0]
                .path}-800{cow.ext}
              768w, ../{cow[0]
                .path}-1200{cow[0].ext} 1024w"
              sizes="(max-width: 1000px) 100vw, 1000px"
            />
            <img
              src="../{cow[0].path}{cow[0].ext}"
              class="main"
              alt={cow[0].alt}
            />
          </picture>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
  section {
    width: 100vw;
  }
  h1 {
    padding: 0 0 0 0.25em;
  }
  h1 a,
  h1 a:visited {
    display: inline-block;
    padding: 0.35em;
    background: var(--secondary);
    color: var(--text);
    vertical-align: middle;
    margin-left: 1em;
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
  }
  .flex div {
    width: calc(50% - 2.25em);
    border: solid 1px var(--secondary);
    margin: 0.5em;
    padding: 0 0.5em;
  }
  @media (max-width: 600px) {
    .flex div {
      width: 100%;
    }
  }
  .cow {
    background: var(--primary);
    color: var(--secondary);
    padding: 1em;
  }
  .cow:last-child {
    margin-right: 0;
  }
  .cow img {
    width: calc(100% - 2em);
    outline: solid 1px var(--secondary);
    outline-offset: -0.5em;
    padding: 1em;
  }
  label {
    display: inline-block;
  }
  span::after {
    display: block;
    content: "";
  }
  a,
  a:visited {
    white-space: nowrap;
    font-size: 0.5em;
    color: var(--secondary);
  }
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 0 0 0.5em;
  }
  li {
    list-style-type: none;
    margin: 0.25em;
    padding: 0;
  }

  li::after {
    content: "|";
    display: inline-block;
    width: 1em;
    text-align: center;
  }
  li:last-child::after {
    content: "";
    display: none;
    width: 0;
  }
  button {
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    background: none;
    border: none;
    font-size: 1em;
    color: var(--a-secondary);
    padding: 0;
    margin: 0;
    cursor: pointer;
    opacity: 1;
  }
  button:hover {
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    opacity: 0.5;
  }
</style>

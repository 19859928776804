export default [
  {
    id: "1",
    alt: "Girl in field with Dexter Cows",
    path: "/g/img/farm/riffle-ridge-dexter-cows-girl",
    ext: ".jpeg",
  },
  {
    id: "2",
    alt: "Chickens on the Porch",
    path: "/g/img/farm/riffle-ridge-chickens-porch",
    ext: ".jpeg",
  },
  {
    id: "3",
    alt: "Horses and Cows Meeting at a Fence",
    path: "/g/img/farm/riffle-ridge-horse-cows",
    ext: ".jpeg",
  },
  {
    id: "4",
    alt: "Guys Loading Trailer with Hay",
    path: "/g/img/farm/riffle-ridge-truck-hay",
    ext: ".jpeg",
  },
  {
    id: "4",
    alt: "Dexter Cows in a Field",
    path: "/g/img/farm/riffle-ridge-dexter-cows-in-field",
    ext: ".jpg",
  },
  {
    id: "5",
    alt: "3 Dexter Cows in a Field",
    path: "/g/img/farm/riffle-ridge-3-cows",
    ext: ".jpeg",
  },
  {
    id: "6",
    alt: "Horses and Cows in a Field",
    path: "/g/img/farm/riffle-ridge-horses-cows-2",
    ext: ".jpeg",
  },
  {
    id: "7",
    alt: "A Chicken in a Tree with a Girl Reaching for It",
    path: "/g/img/farm/riffle-ridge-chicken-tree",
    ext: ".jpeg",
  },
  {
    id: "8",
    alt: "Chickens on a Bucket",
    path: "/g/img/farm/riffle-ridge-chickens",
    ext: ".jpeg",
  },
  {
    id: "9",
    alt: "Cows and Bull in a Field",
    path: "/g/img/farm/riffle-ridge-cows-bull-field",
    ext: ".jpeg",
  },
  {
    id: "10",
    alt: "A Horse behind a Tree",
    path: "/g/img/farm/riffle-ridge-horse-tree",
    ext: ".jpeg",
  },
];

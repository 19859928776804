export default [
  {
    id: "bwf-penny",
    name: "Penny",
    farm: "BWF",
    regNum: "044183",
    volume: "XXXI",
    gender: "Cow",
    polled: "No",
    color: "Black",
    chon: "N/A",
    pha: "N/A",
    dna: "Sire Qualifies",
    lab: "N/A",
    dob: "10/01/2017",
    cowAlt: "the Dexter Cow",
    path: "g/img/cows/penny",
    ext: ".jpeg",
  },
  {
    id: "morning-star-ellie",
    name: "Ellie",
    farm: "Morning Star",
    regNum: "046402",
    volume: "XXXI",
    gender: "Cow",
    polled: "No",
    color: "Dun",
    chon: "Non-carrier",
    pha: "Non-carrier",
    dna: "Sire and Dam Qualify",
    lab: "UCD DEX5305",
    dob: "06/27/2019",
    cowAlt: "the Dexter Cow",
    path: "g/img/cows/cow-sample",
    ext: ".jpeg",
  },
  {
    id: "morning-star-nia",
    name: "Nia",
    farm: "Morning Star",
    regNum: "046426",
    volume: "XXXI",
    gender: "Cow",
    polled: "No",
    color: "Dun",
    chon: "N/A",
    pha: "Non-carrier",
    dna: "Sire and Dam Qualify",
    lab: "UCD DEX5306",
    dob: "03/23/2019",
    cowAlt: "the Dexter Cow",
    path: "g/img/cows/morning-star-nia",
    ext: ".jpeg",
  },
  {
    id: "p-h-honey-bun",
    name: "Honey Bun",
    farm: "P & H",
    regNum: "046564",
    volume: "XXXI",
    gender: "Cow",
    polled: "Yes",
    color: "Red",
    chon: "Non-carrier",
    pha: "Non-carrier",
    dna: "Sire and Dam Qualify",
    lab: "TAMU 117447",
    dob: "11/12/2019",
    cowAlt: "the Dexter Cow",
    path: "g/img/cows/cow-sample",
    ext: ".jpeg",
  },
  {
    id: "p-h-lily-of-the-valley",
    name: "Lily of the Valley",
    farm: "P & H",
    regNum: "046560",
    volume: "XXXI",
    gender: "Cow",
    polled: "Yes",
    color: "Red",
    chon: "Non-carrier",
    pha: "Non-carrier",
    dna: "Sire and Dam Qualify",
    lab: "TAMU 117446",
    dob: "11/07/2019",
    cowAlt: "the Dexter Cow",
    path: "g/img/cows/lily-of-the-valley",
    ext: ".jpg",
  },
  {
    id: "rrl-pollyanna",
    name: "Pollyanna",
    farm: "RRL",
    regNum: "047199",
    volume: "XXXI",
    gender: "Cow",
    polled: "Yes",
    color: "Black",
    chon: "Non-carrier",
    pha: "Non-carrier",
    dna: "Sire and Dam Qualify",
    lab: "N/A",
    dob: "05/13/2020",
    cowAlt: "the Dexter Cow",
    path: "g/img/cows/pollyanna",
    ext: ".jpeg",
  },
];

<script>
  import Navbar from "./Navbar.svelte";
  import Sidebar from "./Sidebar.svelte";

  export let position = "absolute";
  export let background = "var(--bg)";
  export let shadow = "var(--box-shadow)";

  //menu
  let open = false;
  let params;
</script>

<header>
  <Sidebar bind:open />
  <Navbar bind:sidebar={open} />
  <div
    class="logo"
    style="position: {position}; background-color: {background}; box-shadow: {shadow};"
  >
    <svg
      id="type-lockup"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 5175 1080"
      enable-background="new 0 0 5175 1080"
      xml:space="preserve"
    >
      <a href="/">
        <g id="logo">
          <g>
            <circle fill="rgba(0, 0, 0, 0)" cx="540" cy="540" r="540" />
          </g>
          <g id="X_1_">
            <line
              fill="none"
              stroke="var(--secondary"
              stroke-width="33.75"
              stroke-miterlimit="10"
              x1="921.84"
              y1="921.84"
              x2="158.16"
              y2="158.16"
            />

            <line
              fill="none"
              stroke="var(--secondary"
              stroke-width="33.75"
              stroke-miterlimit="10"
              x1="158.16"
              y1="921.84"
              x2="921.84"
              y2="158.16"
            />
            <g>
              <path
                d="M540,13.5c68.39,0,135.05,12.97,198.13,38.55c65.3,26.48,123.9,65.39,174.16,115.66
                c50.26,50.26,89.18,108.86,115.66,174.16c25.58,63.08,38.55,129.74,38.55,198.13c0,68.39-12.97,135.05-38.55,198.13
                c-26.48,65.3-65.39,123.9-115.66,174.16c-50.26,50.26-108.86,89.18-174.16,115.66c-63.08,25.58-129.74,38.55-198.13,38.55
                c-68.39,0-135.05-12.97-198.13-38.55c-65.3-26.48-123.9-65.39-174.16-115.66c-50.26-50.26-89.18-108.86-115.66-174.16
                C26.47,675.05,13.5,608.39,13.5,540c0-68.39,12.97-135.05,38.55-198.13c26.48-65.3,65.39-123.9,115.66-174.16
                c50.26-50.26,108.86-89.18,174.16-115.66C404.95,26.47,471.61,13.5,540,13.5
                M540,0C401.8,0,263.6,52.72,158.16,158.16
                c-210.88,210.88-210.88,552.79,0,763.68C263.6,1027.28,401.8,1080,540,1080s276.4-52.72,381.84-158.16
                c210.88-210.88,210.88-552.79,0-763.68C816.4,52.72,678.2,0,540,0L540,0z"
              />
            </g>
          </g>
          <g id="R_1_">
            <path
              fill="var(--tertiary)"
              d="M642.1,161.26c0-42.96-34.76-75.88-77.79-77.79L439.43,81.5v272.47h56.25V244.06h44.02l45.86,109.92h56.25l-50.04-119.93
              C621.18,222.94,642.1,194.55,642.1,161.26z
              M495.68,201.28v-72.35h55.1c20.84,1.45,36.2,16.21,36.2,36.2
              c0,19.7-15.74,35.69-35.33,36.15h-29.8H495.68z"
            />
            <path
              fill="var(--tertiary)"
              d="M642.1,769.23c0-42.96-34.76-75.88-77.79-77.79l-124.88-1.97v272.47h56.25V852.03h44.02l45.86,109.91h56.25l-50.04-119.93
              C621.18,830.92,642.1,802.52,642.1,769.23z
              M495.68,809.25V736.9h55.1c20.84,1.45,36.2,16.21,36.2,36.2
              c0,19.7-15.74,35.69-35.33,36.16h-29.8H495.68z"
            />
          </g>
          <g id="cowTrack_1_">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M332.68,509.59c-0.7,8.11-1.21,16.24-3.54,24.11
              c-1.49,5.03-3.08,10.02-5.32,14.76c-3.75,7.95-9.95,11.11-18.52,9.34c-6.57-1.35-12.66-3.59-17.13-9.2c-3.75-4.71-6-4.48-9.51,0.4
              c-3.29,4.56-6.64,8.95-11.66,11.82c-8.33,4.77-14.85,3.46-20.05-4.6c-5.45-8.45-9.57-17.55-12.32-27.26
              c-9.18-32.42-4.43-63.33,9.93-93.24c3.44-7.16,7.08-14.17,13.06-19.63c1.62-0.17,3.25-0.33,4.87-0.5c0.88,1.7,1.15,3.47,0.91,5.4
              c-1.83,14.43-1.89,28.72,2.85,42.75c2.6,7.69,3.55,15.91,7.25,23.26c0.72,1.44,1.46,3.12,3.51,2.84c2-0.27,2.38-2.07,2.75-3.64
              c0.88-3.76,0.69-7.63,0.98-11.45c0.54-7.07,1.08-14.14,1.63-21.21c0.89-11.35-2.67-21.81-6.35-32.26
              c-0.81-2.31-1.59-4.66-0.3-7.08c1.62-0.17,3.25-0.33,4.87-0.5c3.95,1.81,7.6,4.08,10.72,7.12c12.87,12.56,23.63,26.64,31.23,43.04
              c3.29,7.11,5.45,14.6,7.71,22.06C331.05,493.82,331.86,501.71,332.68,509.59z"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M118.45,634.41c0.68-11.64,2.06-23.17,5.37-34.41
              c3.61-12.25,8.61-23.88,15.06-34.88c1.83-3.12,3.99-6.05,6.9-8.27c1.37-1.05,2.93-2.17,4.79-1.16c1.84,1.01,1.77,2.9,1.65,4.64
              c-0.48,7.19-1.73,14.33-1.16,21.59c1.16,14.88,5.62,28.96,10.29,42.99c0.69,2.07,1.42,5.15,4.23,4.85
              c3.05-0.32,3.05-3.54,3.24-5.88c0.9-11.25,1.92-22.5,2.32-33.78c0.3-8.34-1.96-16.37-4.77-24.21c-0.71-1.97-1.48-3.93-2.07-5.93
              c-0.55-1.88-1.36-3.97,0.33-5.6c1.82-1.75,3.96-0.78,5.92-0.06c3,1.11,5.57,2.95,7.87,5.1c19.82,18.43,34.43,40.11,40.37,66.83
              c4.07,18.32,3.34,36.54-3.21,54.33c-0.32,0.88-0.68,1.74-0.94,2.63c-1.77,5.88-4.78,10.85-10.22,14.05
              c-3.02,0.31-6.03,0.62-9.04,0.94c-7.37-1.86-14.34-4.14-19.37-10.61c-2.87-3.69-5.62-3.27-8.36,0.93
              c-3.96,6.09-8.87,10.96-15.42,14.13c-2.55,0.26-5.1,0.53-7.65,0.79c-5.38-1.96-8.41-6.23-11.16-10.94
              c-5.66-9.7-9.31-20.16-12.16-30.96C120.32,652.5,119.38,643.46,118.45,634.41z"
            />
          </g>
          <g id="chickenTrack_1_">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M822.16,487.87c2.6-12.37,4.4-24.02,7.57-35.03
              c3.16-10.97,5.16-22.22,8.51-33.13c1.65-5.38,4.01-10.13,6.92-14.76c0.59-0.95,1.03-2.48,2.56-2.04c1.59,0.46,1.72,1.98,1.68,3.4
              c-0.26,10.83-1.29,21.61-4.35,32.01c-1.65,5.62-3.11,11.2-3.72,17.03c-1.43,13.79-3.44,27.49-7.98,40.96
              c2.58,0.44,3.23-1.22,4.01-2.3c2.34-3.23,5.12-6.01,8.16-8.55c2.55-2.12,4.46-4.55,5.63-7.79c1.8-4.99,7.11-7.08,10.05-11.4
              c3.93-5.76,8.34-11.33,13.69-15.99c1.74-1.52,3.86-3.07,6.01-1.86c2.52,1.42,1.28,4.15,0.43,5.99
              c-4.32,9.35-10.17,17.82-15.79,26.42c-6.77,10.36-16.52,17.81-25.34,26.18c-2.75,2.61-5.64,5.13-7,8.79
              c-4.83,12.98-13.08,24.54-15.76,38.45c-1.22,6.35-6.38,9.96-10.98,13.82c-0.62,0.52-1.42,1.24-2.31,0.69
              c-1.08-0.66-0.67-1.79-0.6-2.79c0.83-10.86,2.58-21.56,8.09-31.1c4.13-7.15,3.05-15.39,5.77-22.78c0.5-1.38-0.28-2.66-1.24-3.74
              c-4.15-4.68-6.19-10.57-9.23-15.88c-7.49-13.1-9.93-27.54-13.17-41.82c-0.49-2.14-2.33-5.24,0.46-6.44c2.32-1,5.14,1.01,6.62,3.04
              c4.65,6.35,9.34,12.67,11.56,20.5C814.37,474.66,817.31,481.24,822.16,487.87z"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M867.79,611.33c3.48-1.44,4.4-4.23,6.13-6.28c7.21-8.5,14.68-16.75,23.08-24.09
              c3.52-3.07,6.89-6.16,9.3-10.21c6.47-10.87,17.29-17.39,26.06-25.96c0.84-0.83,2.1-1.67,3.25-1.03c1.4,0.77,0.39,2.14,0.19,3.24
              c-0.75,4.13-2.15,8.29-4.88,11.22c-6.58,7.03-12.35,14.74-18.32,22.2c-6.31,7.88-13.04,15.33-19.55,23
              c-2.33,2.74-4.2,5.83-6.99,8.22c-1.04,0.89-2.85,2.18-1.94,3.76c0.84,1.44,2.85,0.76,4.32,0.6c1.34-0.14,2.67-0.62,3.93-1.13
              c11.62-4.66,23.79-6.26,36.18-6.63c1.61-0.05,3.01,0.56,4.36,1.35c2.52,1.48,2.69,3.04-0.01,4.41c-3.67,1.86-7.44,3.52-11.14,5.33
              c-8.4,4.12-16.29,9.47-26.33,9.13c-2.96-0.1-5.94,1.33-8.82,2.57c-2.47,1.06-5.42,2.08-7.96,1.73
              c-16.84-2.35-21.84,11.24-28.03,21.73c-5.37,9.09-12.52,16.21-19.5,23.7c-0.51,0.55-1.57,1.07-2.21,0.91
              c-1.28-0.32-0.88-1.67-0.89-2.62c-0.06-6.29,0.79-12.26,5.04-17.38c9.4-11.32,14.81-24.98,21.7-37.76
              c3.26-6.05,2.25-13.12,2.9-19.72c1.28-13.08,3.78-25.83,9.64-37.63c2.39-4.8,2.56-10.32,5.68-14.8c1.54-2.21,2.73-5.73,5.71-4.88
              c3.07,0.87,2.42,4.59,2.27,7.24c-0.16,2.72-0.71,5.43-1.31,8.1C875.79,576.68,870.66,593.45,867.79,611.33z"
            />
          </g>
        </g>
      </a>
      <g id="type">
        <g>
          <path
            d="M1618.4,706.56h-74.38l-104.47-245.29h-162.31v245.29h-66.41V140.03h223.29c60.6,0,105.48,16.54,134.68,49.6
            c29.19,33.07,43.79,69.25,43.79,108.53c0,30.16-8.17,59.08-24.49,86.74c-16.33,27.67-43.31,47.46-80.93,59.35L1618.4,706.56z
            M1277.24,403.58h148.17c39.01,0,68.13-10.3,87.37-30.92c19.23-20.61,28.85-45.86,28.85-75.74s-10.17-53.12-30.51-69.73
            c-20.34-16.6-46-24.9-76.99-24.9h-156.88V403.58z"
          />
          <path d="M1781.34,706.56h-49.68V309.99h49.68V706.56z" />
          <path
            d="M1944.97,706.56h-49.68V309.99h242.88v43.58h-193.2v125.22h160.08v40.09h-160.08V706.56z"
          />
          <path
            d="M2261.99,706.56h-49.68V309.99h242.88v43.58h-193.2v125.22h160.08v40.09h-160.08V706.56z"
          />
          <path d="M2763.5,662.98v43.58h-234.17V309.99h50.84v352.99H2763.5z" />
          <path
            d="M3092.14,662.98v43.58h-256.54V309.99h251.02v43.58h-201.33v126.09h159.79v40.38h-159.79v142.94H3092.14z"
          />
          <path
            d="M3839.06,706.56h-74.38l-104.47-245.29H3497.9v245.29h-66.41V140.03h223.29c60.6,0,105.48,16.54,134.68,49.6
            c29.19,33.07,43.79,69.25,43.79,108.53c0,30.16-8.17,59.08-24.49,86.74c-16.33,27.67-43.31,47.46-80.93,59.35L3839.06,706.56z
            M3497.9,403.58h148.17c39.01,0,68.13-10.3,87.37-30.92c19.23-20.61,28.85-45.86,28.85-75.74s-10.17-53.12-30.51-69.73
            c-20.34-16.6-46-24.9-76.99-24.9H3497.9V403.58z"
          />
          <path d="M4002.01,706.56h-49.68V309.99h49.68V706.56z" />
          <path
            d="M4115.95,706.56V309.99h133.06c55.97,0,99.55,19.08,130.74,57.23c31.18,38.16,46.78,84.83,46.78,140.03
            c0,55.98-15.21,103.19-45.61,141.63c-30.41,38.45-68.95,57.67-115.63,57.67H4115.95z
            M4165.63,664.43h80.48
            c42.61,0,74.96-14.72,97.04-44.16c22.08-29.44,33.12-68.27,33.12-116.5c0-39.12-10.51-74.28-31.52-105.46
            c-21.02-31.18-53.89-46.77-98.63-46.77h-80.48V664.43z"
          />
          <path
            d="M4809.5,506.68v205.4h-30.36l-13.65-49.68c-24.42,34.86-60.75,52.29-109.01,52.29c-52.32,0-93.02-19.67-122.08-59.02
            c-29.07-39.34-43.6-87.12-43.6-143.33c0-60.08,15.4-110.19,46.19-150.31s72.63-60.18,125.51-60.18
            c73.02,0,120.66,38.35,142.94,115.05l-47.07,11.33c-6.78-29.44-19.47-50.84-38.06-64.21s-38.93-20.05-61.01-20.05
            c-39.13,0-67.98,15.8-86.58,47.4c-18.59,31.6-27.89,71.24-27.89,118.93c0,51.37,10.41,91.55,31.23,120.53
            c20.82,28.98,49.14,43.47,84.98,43.47c27.89,0,51.18-9.27,69.87-27.81c18.69-18.54,28.04-44.11,28.04-76.73
            c0-6.02-0.2-13.2-0.58-21.55h-98.2v-41.54H4809.5z"
          />
          <path
            d="M5172.14,662.98v43.58H4915.6V309.99h251.02v43.58h-201.33v126.09h159.79v40.38h-159.79v142.94H5172.14z"
          />
        </g>
        <g>
          <path
            d="M1260.31,794.01v97.28c0,16.97-3.83,28.8-11.48,35.5c-7.66,6.7-17.81,10.06-30.45,10.06c-6.77,0-13.57-0.96-20.41-2.87
            v-14.36c6.7,1.44,12.78,2.15,18.25,2.15c7.79,0,13.54-1.16,17.23-3.49c3.69-2.33,6.17-5.34,7.43-9.03
            c1.26-3.69,1.9-9.68,1.9-17.96v-97.29H1260.31z"
          />
          <path
            d="M1384.22,794.01h14.56v91.43c0,18.33-4.5,31.49-13.48,39.46c-8.99,7.97-20.76,11.95-35.33,11.95
            c-16.2,0-28.2-4.1-35.99-12.31c-7.79-8.21-11.69-21.24-11.69-39.1v-91.43h17.53v91.44c0,10.88,1.26,18.54,3.79,22.99
            c2.53,4.45,6.36,7.73,11.48,9.85c5.13,2.12,10.49,3.18,16.1,3.18c10.8,0,19-2.84,24.61-8.52c5.6-5.68,8.41-14.85,8.41-27.5V794.01
            z"
          />
          <path
            d="M1426.81,896.96l15.79-3.59c6.36,19.07,20.13,28.61,41.32,28.61c11.07,0,19.41-2.35,25.02-7.05
            c5.6-4.7,8.41-10.99,8.41-18.88c0-5.97-2.14-10.77-6.41-14.41c-4.27-3.63-9.21-6.14-14.81-7.51l-28-6.9
            c-7.25-1.85-13.18-4.09-17.79-6.73c-4.61-2.64-8.53-6.69-11.74-12.17c-3.21-5.48-4.82-11.74-4.82-18.79
            c0-11.71,4.41-21.03,13.23-27.98s20.13-10.42,33.94-10.42c25.43,0,42.14,11.52,50.14,34.56l-15.89,4.72
            c-1.85-8.2-6.19-14.41-13.02-18.61c-6.84-4.2-14.12-6.31-21.84-6.31c-7.66,0-14.48,2.03-20.46,6.08
            c-5.98,4.05-8.97,9.79-8.97,17.2c0,10.37,7.58,17.38,22.74,21.02l26.17,6.14c23.92,5.74,35.89,18.63,35.89,38.66
            c0,12.1-4.5,22.17-13.48,30.2c-8.99,8.03-21.65,12.05-37.99,12.05C1455.25,936.85,1436.11,923.55,1426.81,896.96z"
          />
          <path
            d="M1610.29,933.98h-17.53V809.39h-40.09v-15.38h97.82v15.38h-40.2V933.98z"
          />
          <path
            d="M1837.73,933.98h-17.9l-14.08-40.91h-51.94l-12.52,40.91h-14.66l45.48-139.97h19.83L1837.73,933.98z
            M1802.02,879.94 l-21.68-67.27l-21.65,67.27H1802.02z"
          />
          <path
            d="M1914.08,896.96l15.79-3.59c6.36,19.07,20.13,28.61,41.32,28.61c11.07,0,19.41-2.35,25.02-7.05
            c5.6-4.7,8.41-10.99,8.41-18.88c0-5.97-2.14-10.77-6.41-14.41c-4.27-3.63-9.21-6.14-14.81-7.51l-28-6.9
            c-7.25-1.85-13.18-4.09-17.79-6.73c-4.61-2.64-8.53-6.69-11.74-12.17c-3.21-5.48-4.82-11.74-4.82-18.79
            c0-11.71,4.41-21.03,13.23-27.98s20.13-10.42,33.94-10.42c25.43,0,42.14,11.52,50.14,34.56l-15.89,4.72
            c-1.85-8.2-6.19-14.41-13.02-18.61c-6.84-4.2-14.12-6.31-21.84-6.31c-7.66,0-14.48,2.03-20.46,6.08
            c-5.98,4.05-8.97,9.79-8.97,17.2c0,10.37,7.58,17.38,22.74,21.02l26.17,6.14c23.92,5.74,35.89,18.63,35.89,38.66
            c0,12.1-4.5,22.17-13.48,30.2c-8.99,8.03-21.65,12.05-37.99,12.05C1942.51,936.85,1923.37,923.55,1914.08,896.96z"
          />
          <path
            d="M2194.67,933.98h-17.02V811.75l-45.43,122.23h-11.89l-45.93-122.23v122.23h-14.87V794.01h25.96l42.28,111.95l41.28-111.95
            h25.63V933.98z"
          />
          <path
            d="M2334.39,933.98h-17.9l-14.08-40.91h-51.94l-12.52,40.91h-14.66l45.48-139.97h19.83L2334.39,933.98z
            M2298.68,879.94 l-21.68-67.27l-21.65,67.27H2298.68z"
          />
          <path d="M2445.76,918.59v15.38h-82.65V794.01h17.94v124.58H2445.76z" />
          <path d="M2559.11,918.59v15.38h-82.65V794.01h17.94v124.58H2559.11z" />
          <path
            d="M2670.34,933.98h-17.53V794.01h85.72v15.38h-68.19v44.19h56.5v14.15h-56.5V933.98z"
          />
          <path
            d="M2854.57,933.98h-17.9l-14.08-40.91h-51.94l-12.52,40.91h-14.66l45.48-139.97h19.83L2854.57,933.98z
            M2818.86,879.94 l-21.68-67.27l-21.65,67.27H2818.86z"
          />
          <path
            d="M3018.44,933.98h-17.02V811.75l-45.43,122.23h-11.89l-45.93-122.23v122.23h-14.87V794.01h25.96l42.28,111.95l41.28-111.95
            h25.63V933.98z"
          />
          <path d="M3081.44,933.98h-17.53V794.01h17.53V933.98z" />
          <path d="M3209.56,918.59v15.38h-82.65V794.01h17.94v124.58H3209.56z" />
          <path
            d="M3317.06,794.01l-46.86,81.11v58.86h-17.53v-58.86l-46.86-81.11h20.73l37.15,64.31l37.39-64.31H3317.06z"
          />
          <path
            d="M3423.58,933.98h-17.53V794.01h85.72v15.38h-68.19v44.19h56.5v14.15h-56.5V933.98z"
          />
          <path
            d="M3607.8,933.98h-17.9l-14.08-40.91h-51.94l-12.52,40.91h-14.66l45.48-139.97h19.83L3607.8,933.98z
            M3572.09,879.94 l-21.68-67.27l-21.65,67.27H3572.09z"
          />
          <path
            d="M3737.22,933.98h-18.38l-25.81-60.6h-40.1v60.6h-16.41V794.01h55.17c14.97,0,26.06,4.09,33.27,12.25
            c7.21,8.17,10.82,17.11,10.82,26.81c0,7.45-2.02,14.6-6.05,21.43c-4.03,6.84-10.7,11.72-20,14.66L3737.22,933.98z
            M3652.93,859.12
            h36.61c9.64,0,16.83-2.55,21.58-7.64c4.75-5.09,7.13-11.33,7.13-18.71s-2.51-13.12-7.54-17.23c-5.02-4.1-11.37-6.15-19.02-6.15
            h-38.76V859.12z"
          />
          <path
            d="M3910.86,933.98h-17.02V811.75l-45.43,122.23h-11.89l-45.93-122.23v122.23h-14.87V794.01h25.96l42.28,111.95l41.28-111.95
            h25.63V933.98z"
          />
        </g>
      </g>
    </svg>
  </div>
</header>

<style>
  header {
    width: 100vw;
    max-width: 100%;
    color: var(--secondary);
    display: flex;
  }
  header a,
  header a:visited {
    color: var(--secondary);
    fill: var(--secondary);
  }
  header a:hover {
    opacity: 1;
    transform: scale(1);
  }
  .logo {
    width: 100vw;
    max-width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0.5em 0;
    text-align: center;
    z-index: 2;
    top: 0;
    left: 0;
  }
  #type-lockup {
    width: 90vw;
    fill: var(--secondary);
    padding: 1em 0;
  }
  @media (min-width: 675px) {
  }
</style>

export default [
  {
    id: "0",
    henAlt: "Collage of Hens",
    hen: "/g/img/hens/group-hens",
    henExt: ".jpg",
    eggAlt: "Group shot of eggs",
    egg: "/g/img/hens/eggs/group-eggs",
    eggExt: ".jpg",
    breed:
      "Blue Splash Maran - Buff Orpington - Light Brahma - Mystic Maran - New Hampshire Red - Plymouth Blue Rock - Silver Grey Dorking - Silver Laced Polish - Silver Laced Wyandotte - White Leghorn - Black Sex Link - Blue Adalusian - Cream Legbar - Olive Egger",
    name:
      "Bunny - Locks - Esther - Malley - Shiloh - Karen - Fiercely - BooBoo - Hopps - Chloe - Snowy",
    color: "Dark Brown",
  },
  {
    id: "1",
    henAlt: "Blue Splash Maran Hen",
    hen: "/g/img/hens/Blue-Splash-Maran-BUNNY-dark-brown",
    henExt: ".jpg",
    eggAlt: "Blue Splash Maran Egg",
    egg: "/g/img/hens/eggs/egg01",
    eggExt: ".jpg",
    breed: "Blue Splash Maran",
    name: "Bunny",
    color: "Dark Brown",
  },
  {
    id: "2",
    henAlt: "Buff Orpington Hen",
    hen: "/g/img/hens/Buff-Orpington-LOCKS-brown",
    henExt: ".jpg",
    eggAlt: "Egg Alt",
    egg: "/g/img/hens/eggs/egg02",
    eggExt: ".jpg",
    breed: "Buff Orpington",
    name: "Locks",
    color: "Brown",
  },
  {
    id: "3",
    henAlt: "Light Brahma",
    hen: "/g/img/hens/Light-Brahma-brown",
    henExt: ".jpg",
    eggAlt: "Egg Alt",
    egg: "/g/img/hens/eggs/egg01",
    eggExt: ".jpg",
    breed: "Light Brahma",
    name: "Light Brahma",
    color: "Brown",
  },
  {
    id: "4",
    henAlt: "Mystic Maran Hens",
    hen: "/g/img/hens/Mystic-Maran-ESTER-&-MALLEY-chocolate-brown",
    henExt: ".jpg",
    eggAlt: "Malley the Mystic Maran Hens Eggs",
    egg: "/g/img/hens/eggs/malley-mm",
    eggExt: ".jpg",
    breed: "Mystic Maran",
    name: "Ester & Malley",
    color: "Chocolate Brown",
  },
  {
    id: "5",
    henAlt: "New Hampshire Red Hens",
    hen: "/g/img/hens/New-Hampshire-Red--SHILOH-brown",
    henExt: ".jpg",
    eggAlt: "Egg Alt",
    egg: "/g/img/hens/eggs/shiloh-nhr",
    eggExt: ".jpg",
    breed: "New Hampshire Red",
    name: "Shiloh",
    color: "Brown",
  },
  {
    id: "6",
    henAlt: "Plymouth Blue Rock Hens",
    hen: "/g/img/hens/Plymouth-Blue-Rock-light-to-medium-brown",
    henExt: ".jpg",
    eggAlt: "Egg Alt",
    egg: "/g/img/hens/eggs/egg02",
    eggExt: ".jpg",
    breed: "Plymouth Blue Rock",
    name: "Plymouth Blue Rock",
    color: "Light to Medium Brown",
  },
  {
    id: "8",
    henAlt: "Silver Laced Polish Hens",
    hen: "/g/img/hens/Silver-Laced-Polish--KAREN-white",
    henExt: ".jpg",
    eggAlt: "Karen the Silver Laced Polish Hens Egg",
    egg: "/g/img/hens/eggs/karen-slp",
    eggExt: ".jpg",
    breed: "Silver Laced Polish",
    name: "Karen",
    color: "White",
  },
  {
    id: "9",
    henAlt: "Silver Laced Wyandotte Hens",
    hen: "/g/img/hens/Silver-Laced-Wyandotte-brown",
    henExt: ".jpg",
    eggAlt: "Silver Laced Wyandotte Hens Egg",
    egg: "/g/img/hens/eggs/egg01",
    eggExt: ".jpg",
    breed: "Silver Laced Wyandotte",
    name: "Silver Laced Wyandotte",
    color: "Brown",
  },
  {
    id: "10",
    henAlt: "White Leghorn Hens",
    hen: "/g/img/hens/White-Leghorn-FIERCELY-white",
    henExt: ".jpg",
    eggAlt: "White Leghorn Hens Egg",
    egg: "/g/img/hens/eggs/egg02",
    eggExt: ".jpg",
    breed: "White Leghorn",
    name: "Fiercely",
    color: "White",
  },
  {
    id: "11",
    henAlt: "Black Sex Link Hens",
    hen: "/g/img/hens/Black-Sex-Link-BOOBOO",
    henExt: ".jpg",
    eggAlt: "Black Sex Link Hens Eggs",
    egg: "/g/img/hens/eggs/egg02",
    eggExt: ".jpg",
    breed: "Black Sex Link",
    name: "BooBoo",
    color: "",
  },
  {
    id: "12",
    henAlt: "Blue Adalusian Hens",
    hen: "/g/img/hens/Blue-Adalusian-HOPPS",
    henExt: ".jpg",
    eggAlt: "Blue Adalusian Hens Egg",
    egg: "/g/img/hens/eggs/egg02",
    eggExt: ".jpg",
    breed: "Blue Adalusian",
    name: "Hopps",
    color: "",
  },
  {
    id: "13",
    henAlt: "Cream Legbar Hens",
    hen: "/g/img/hens/Cream-Legbar-CHLOE",
    henExt: ".jpg",
    eggAlt: "Cream Legbar Hens Egg",
    egg: "/g/img/hens/eggs/egg02",
    eggExt: ".jpg",
    breed: "Cream Legbar",
    name: "Chloe",
    color: "",
  },
  {
    id: "14",
    henAlt: "Olive Egger Hens",
    hen: "/g/img/hens/Olive-Egger",
    henExt: ".jpg",
    eggAlt: "Olive Egger Hens Egg",
    egg: "/g/img/hens/eggs/egg02",
    eggExt: ".jpg",
    breed: "Olive Egger",
    name: "-- the girls are still thinking about it --",
    color: "",
  },
  {
    id: "15",
    henAlt: "Barred Rock Hens",
    hen: "/g/img/hens/Barred-Rock-VIRGINIA",
    henExt: ".jpg",
    eggAlt: "Barred Rock Hens Egg",
    egg: "/g/img/hens/eggs/egg02",
    eggExt: ".jpg",
    breed: "Barred Rock",
    name: "Virginia",
    color: "",
  },
];

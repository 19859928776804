<script>
  // https://www.npmjs.com/package/svelte-swipe
  import { Swipe, SwipeItem } from "svelte-swipe";
  import images from "./hens.js";
  import Image from "svelte-image";

  let active_item = 0; //readonly
  let SwipeComp;

  function changeSlide(i) {
    SwipeComp.goTo(i);
  }
</script>

<section id="eggs">
  <article>
    <div class="hens">
      <h1>Farm Fresh Eggs</h1>
      <div class="flex">
        <div class="imageDiv">
          <div class="wrapper" style="min-height: 100px; width: 100%;">
            <div class="loaded" style="position: relative; width: 100%;">
              <div style="position: relative; overflow: hidden;">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/g/img/riffle-ridge-clutch-of-eggs-400.webp 400w"
                    sizes="(max-width: 900px) 100vw, 900px"
                  />
                  <source
                    srcset="/g/img/riffle-ridge-clutch-of-eggs-400.jpg 400w"
                    sizes="(max-width: 900px) 100vw, 900px"
                  />
                  <img
                    src="/g/img/riffle-ridge-clutch-of-eggs.jpg"
                    class="main"
                    alt="Clutch of Eggs"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
        <p>
          While it may not be the main focus of our farm, raising laying hens
          for eggs is definitely enjoyed here at Riffle Ridge. We have a variety
          of breeds to give us a wide array of egg colors. We do have a secure
          coop for the ladies to sleep and nest in, but during the daylight
          hours, you will find the hens exploring all over as they are free
          range in the purest sense of the phrase. Throughout the day, we enjoy
          watching them make their way around the farm, visiting with the cows
          and horses and even stopping by to mingle with the barn cats before
          exploring the woods and pastures to see what's new. You know dusk is
          approaching as they all come back near the coop for the last hour or
          so of daylight before settling in for the night.
        </p>
      </div>
      <!-- <h1>Meet the Hens</h1> -->
      <div class="swipe-holder">
        <Swipe
          bind:active_item
          bind:this={SwipeComp}
          showIndicators={true}
          autoplay={true}
          delay={10000}
        >
          {#each images as image}
            <SwipeItem>
              <div class="imgGroup">
                <!-- <img src={image.hen} alt={image.henAlt} /> -->
                <div class="henWrapper">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="{image.hen}-400.webp 375w, {image.hen}-800.webp
                    768w, {image.hen}-1200.webp 1024w"
                      sizes="(max-width: 1000px) 100vw, 1000px"
                    />
                    <source
                      srcset="{image.hen}-400{image.henExt} 375w, {image.hen}-800{image.henExt}
                    768w, {image.hen}-1200{image.henExt} 1024w"
                      sizes="(max-width: 1000px) 100vw, 1000px"
                    />
                    <img
                      src="{image.hen}{image.henExt}"
                      class="main"
                      alt={image.henAlt}
                    />
                  </picture>
                </div>
                <!-- <img src="{image.egg}{image.eggExt}" alt={image.eggAlt} /> -->
                <div class="henWrapper">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="{image.egg}-400.webp 375w, {image.egg}-800.webp 768w"
                      sizes="(max-width: 1000px) 100vw, 1000px"
                    />
                    <source
                      srcset="{image.egg}-400{image.eggExt} 375w, {image.egg}-800{image.eggExt}
                    768w"
                      sizes="(max-width: 1000px) 100vw, 1000px"
                    />
                    <img
                      src="{image.egg}{image.eggExt}"
                      class="main"
                      alt={image.eggAlt}
                    />
                  </picture>
                </div>
                <div class="chickenNames">
                  <p>Breed: {image.breed}</p>
                  <p>Name(s): {image.name}</p>
                </div>
              </div>
            </SwipeItem>
          {/each}
        </Swipe>
      </div>
      <div class="thumbs">
        {#each images as image, i}
          <img
            class="img-thumbnail {active_item == i
              ? 'active'
              : 'img-thumbnail'}"
            on:click={() => changeSlide(i)}
            style="cursor:pointer"
            src="{image.hen}-400{image.henExt}"
            alt={image.henAlt}
          />
        {/each}
      </div>
    </div>
  </article>
</section>

<style>
  :root {
    --hen-width: 900px;
    --hen-height: calc(var(--hen-width) / 2);
    --sv-swipe-indicator-active-color: var(--secondary);
  }
  section {
    width: 100vw;
    max-width: 100%;
    background: var(--primary);
    color: var(--secondary);
  }

  article {
    width: var(--max-width);
    margin: 0 auto;
  }
  article .flex {
    display: inline-flex;
    align-items: center;
    flex: 2;
    flex-direction: row;
    max-width: var(--max-width);
    padding-left: 1em;
    margin-bottom: 1em;
  }
  article .imageDiv {
    width: 100%;
    min-width: 300px;
    outline: solid 1px var(--secondary);
    outline-offset: 0.25em;
    margin-top: 1em;
  }
  article .imageDiv img {
    width: 100%;
  }
  article p {
    margin-left: 1em;
  }

  .hens {
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 1em 0;
  }
  h1 {
    padding-left: 0.25em;
    margin: 0.5em 0;
  }
  .swipe-holder {
    height: var(--hen-height);
    width: 100%;
  }
  .imgGroup {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    outline: solid 1px var(--secondary);
    outline-offset: -0.5em;
    padding: 1em;
  }
  .imgGroup .henWrapper {
    flex: 1 1 50%;
    padding: 0;
    margin: 0 auto;
    max-width: 50%;
    max-height: calc(var(--hen-height) - 2em);
    overflow: hidden;
  }
  .imgGroup .henWrapper img {
    width: 100%;
    min-height: 100px;
  }
  .imgGroup .chickenNames {
    display: block;
    position: absolute;
    bottom: 1em;
    left: 1em;
    width: calc(100% - 3em);
    padding: 0 0 0 1em;
    margin: 0;
    background: var(--bg);
    color: var(--secondary);
  }
  @media only screen and (max-width: 900px) {
    .swipe-holder {
      max-width: 100%;
      height: calc(100vw * 2);
    }
    .imgGroup {
      flex-flow: column nowrap;
      height: calc((100vw * 2) - 4em);
    }
    .imgGroup .henWrapper {
      max-width: 100vw;
      max-height: 100vw;
    }
    .imgGroup .chickenNames {
      bottom: 3em;
    }
  }
  @media only screen and (max-width: 900px) {
    article {
      width: 100%;
    }
    article .flex {
      flex-direction: column;
      padding: 1em;
    }
    article .flex .imageDiv {
      width: 100%;
      min-width: 10px;
    }
    article .flex p {
      margin-left: 0em;
    }
    .imgGroup .chickenNames {
      padding: 0 0 0 0.5em;
    }
  }

  .thumbs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1em;
  }
  .img-thumbnail {
    margin: 0.5em;
    box-shadow: var(--box-shadow);
    height: 5em;
    width: 5em;
    transform: scale(1);
    transition: all 0.2s ease-in-out;
  }
  .active {
    border-radius: 0.5em;
    transform: scale(1.25);
    z-index: 10;
    transition: all 0.2s ease-in-out;
  }
</style>

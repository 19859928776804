<script>
  import cows from "./cows.js";
  import Image from "svelte-image";
</script>

<section id="cattle">
  <article>
    <h1>Dexter Cattle</h1>
    <div class="flex">
      <div class="imageDiv">
        <!-- <Image src="/img/riffle-ridge-dexter-cow.JPG" alt="Dexter Cow" /> -->
        <div class="wrapper" style="min-height: 100px; width: 100%;">
          <div class="loaded" style="position: relative; width: 100%;">
            <div style="position: relative; overflow: hidden;">
              <picture>
                <source
                  type="image/webp"
                  srcset="/g/img/riffle-ridge-dexter-cow-400.webp 400w, /g/img/riffle-ridge-dexter-cow-800.webp 800w, /g/img/riffle-ridge-dexter-cow-1200.webp
                1200w"
                  sizes="(max-width: 900px) 100vw, 900px"
                />
                <source
                  srcset="/g/img/riffle-ridge-dexter-cow-400.JPG 400w, /g/img/riffle-ridge-dexter-cow-800.JPG
                800w, /g/img/riffle-ridge-dexter-cow-1200.JPG 1200w"
                  sizes="(max-width: 900px) 100vw, 900px"
                />
                <img
                  src="/g/img/riffle-ridge-dexter-cow.JPG"
                  class="main"
                  alt="Dexter Cattle"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
      <p>
        Our main focus is on breeding purebred, registered, Dexter Cattle. They
        are a wonderful breed of cattle originating from Ireland. They are not
        considered a miniature breed though they are small (as far as cows go
        anyway). They are also very docile making them a great breed for a small
        family farm. They are commonly considered dual purpose as they are good
        for both milking and meat, however some consider them a triple- purpose
        breed as they can also be used as oxen. We register all of our Dexters
        with the
        <a href="https://dextercattle.org/" target="_blank">
          American Dexter Cattle Association.
        </a>
      </p>
    </div>
    <div class="cows">
      <!-- <h1>Meet the Cows</h1> -->
      {#each cows as cow}
        <div class="cow">
          <a href="cow/{cow.id}">
            <label for="name">Name:</label>
            <span id="name">{cow.name}</span>
            <label for="regNum">ADCA Reg#:</label>
            <span id="regNum">{cow.regNum}</span>
            <!-- <img src={cow.path}{cow.ext} alt={cow.alt} /> -->
            <div class="wrapper" style="min-height: 100px; width: 100%;">
              <div class="loaded" style="position: relative; width: 100%;">
                <div style="position: relative; overflow: hidden;">
                  <picture>
                    <source
                      type="image/webp"
                      srcset="{cow.path}-400.webp 400w, {cow.path}-800.webp 800w, {cow.path}-1200.webp
                    1200w"
                      sizes="(max-width: 900px) 100vw, 900px"
                    />
                    <source
                      srcset="{cow.path}-400{cow.ext} 400w, {cow.path}-800{cow.ext}
                    800w, {cow.path}-1200{cow.ext} 1200w"
                      sizes="(max-width: 900px) 100vw, 900px"
                    />
                    <img src="{cow.path}{cow.ext}" class="main" alt={cow.alt} />
                  </picture>
                </div>
              </div>
            </div>
          </a>
        </div>
      {/each}
    </div>
  </article>
</section>

<style>
  section {
    width: 100vw;
    max-width: 100%;
  }
  article {
    width: var(--max-width);
    padding: 1em;
    margin: 0 auto;
  }
  article .flex {
    display: inline-flex;
    align-items: center;
    flex: 2;
    flex-direction: row;
    max-width: var(--max-width);
    padding-left: 2em;
  }
  article .imageDiv {
    width: 100%;
    min-width: 300px;
    outline: solid 1px var(--primary);
    outline-offset: 0.25em;
    margin-top: 1em;
  }
  article .imageDiv img {
    width: 100%;
  }
  article p {
    margin-left: 1em;
  }

  .cows {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 1;
    max-width: var(--max-width);
    margin: 0 auto;
  }
  h1 {
    margin: 0;
    padding: 0 0 0 1em;
  }
  .cow {
    max-width: calc(var(--max-width) / 3 - 3em);
    margin-right: 1em;
    padding: 1em;
  }
  .cow:last-child {
    margin-right: 0;
  }
  .cow img {
    width: calc(100% - 2em);
    outline: solid 1px var(--primary);
    outline-offset: -0.5em;
    padding: 1em;
  }
  label {
    display: inline-block;
  }
  span::after {
    display: block;
    content: "";
  }
  a,
  a:visited {
    color: var(--primary);
  }
  picture {
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    opacity: 1;
  }
  a:hover picture {
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    opacity: 0.5;
  }
  @media only screen and (max-width: 900px) {
    article {
      width: calc(100vw - 2em);
      margin: 0;
    }
    article .flex {
      flex-direction: column;
      padding: 0;
    }
    article .imageDiv {
      width: 100%;
      min-width: 10px;
    }
    article p {
      margin-left: 0em;
    }
    .cow {
      max-width: 100%;
      margin: 0;
    }
  }
</style>

<script>
  import Header from "./Header.svelte";
</script>

<style>
  section {
    padding: 1em 0;
    border-bottom: 1px solid var(--primary);
  }
  .success {
    max-width: var(--max-width);
    margin: 0 auto;
    text-align: center;
  }
  button {
    margin: 1em;
    cursor: pointer;
  }
</style>

<Header position={'relative'} background={'var(--primary)'} shadow={'none'} />
<section>
  <div class="success">
    <h1>You have successfully submitted the form.</h1>
    <button
      on:click={() => {
        window.location.href = '/';
      }}>
      Click Here to Continue
    </button>
  </div>
</section>

<script>
  // import { scrollto } from "svelte-scrollto";
  // export let params;
  export let open = false;

  // List of navigation items
  const navItems = [
    { label: "Home", scroll: "#home", href: "/#home" },
    { label: "Dexter Cattle", scroll: "#cattle", href: "/#cattle" },
    { label: "Farm Fresh Eggs", scroll: "#eggs", href: "/#eggs" },
    { label: "Beef Steer", scroll: "#steer", href: "/#steer" },
    { label: "Contact", scroll: "#contact", href: "/#contact" },
  ];

  // isolate the word COW in the url for if statement
  const url = window.location.href;
  const splitUrl = url.split("/");
</script>

<aside class:open>
  <nav>
    <ul>
      {#if splitUrl[3] === "cow" || splitUrl[3] === "success"}
        {#each navItems as item}
          <li>
            <button
              on:click={() => {
                window.location.href = item.href;
              }}
            >
              {item.label}
            </button>
          </li>
        {/each}
      {:else}
        {#each navItems as item}
          <li>
            <a href={item.href} on:click={() => (open = !open)}>{item.label}</a>
          </li>
        {/each}
      {/if}
    </ul>
  </nav>
</aside>

<style>
  aside {
    position: fixed;
    z-index: 100;
    padding: 3em 1em 1em 1em;
    top: 0;
    left: -100%;
    transition: left 0.3s ease-in-out;
    color: var(--secondary);
    background: var(--bg);
    box-shadow: var(--box-shadow);
  }
  a,
  a:visited {
    color: var(--a-secondary);
  }
  .open {
    left: 0;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    padding-bottom: 0.5em;
    list-style-type: none;
  }
  button {
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    background: none;
    border: none;
    font-size: 1em;
    color: var(--a-secondary);
    padding: 0;
    margin: 0;
    cursor: pointer;
    opacity: 1;
  }
  button:hover {
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    opacity: 0.5;
  }
</style>

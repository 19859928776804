<script>
  import { onMount } from "svelte";
  import router from "page";
  import Home from "./Home.svelte";
  import cow from "./Cow.svelte";
  import Footer from "./Footer.svelte";
  import Header from "./Header.svelte";
  import Success from "./Success.svelte";
  import NotFound from "./NotFound.svelte";
  import Contact from "./Contact.svelte";

  let page;
  router("/", () => (page = Home));
  router("/success", () => (page = Success));
  router("/contact", () => (page = Contact));

  let params;
  router(
    "/cow/:id",
    (ctx, next) => {
      params = ctx.params;
      next();
      // console.log("name is ", ctx.params.id);
    },
    () => (page = cow)
  );
  router("*", () => (page = NotFound));

  router.start();

  // onMount(() => console.log("params from App " + params));
</script>

<main>
  <svelte:component this={page} {params} />
  <Footer />
</main>

<style>
  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>

<script>
  // import Swiper core and required components
  import SwiperCore, {
    Navigation,
    Keyboard,
    A11y,
    Autoplay,
    Lazy,
  } from "swiper";

  // Import Swiper Svelte components
  import { Swiper, SwiperSlide } from "swiper/svelte";

  // Import Swiper styles
  import "swiper/swiper.min.css";
  import "swiper/components/a11y/a11y.min.css";
  import "swiper/components/navigation/navigation.min.css";
  import "swiper/components/lazy/lazy.min.css";

  // install Swiper components
  SwiperCore.use([Navigation, Keyboard, Navigation, A11y, Lazy, Autoplay]);

  // loading images from farm.js
  import images from "./farm.js";
</script>

<section id="home">
  <div class="about">
    <div class="tagline">
      <p>
        We started with a dream, added some chickens and then threw in some cows
        - time will tell what comes next.
      </p>
    </div>
    <div class="background">
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        navigation
        a11y
        keyboard
        loop={true}
        lazy
        autoplay={{ delay: 10000, disableOnInteraction: true }}
      >
        {#each images as image}
          <SwiperSlide>
            <!-- <img class="swiper-lazy" src={image.path} alt={image.alt} /> -->
            <div class="wrapper" style="min-height: 100px; width: 100%;">
              <div class="loaded" style="position: relative; width: 100%;">
                <div style="position: relative; overflow: hidden;">
                  <picture class="swiper-lazy">
                    <source
                      type="image/webp"
                      srcset="{image.path}-400.webp 375w, {image.path}-800.webp
                      768w, {image.path}-1200.webp 1024w"
                      sizes="(max-width: 1000px) 100vw, 1000px"
                    />
                    <source
                      srcset="{image.path}-400{image.ext} 375w, {image.path}-800{image.ext}
                      768w, {image.path}-1200{image.ext} 1024w"
                      sizes="(max-width: 1000px) 100vw, 1000px"
                    />
                    <img
                      src="{image.path}{image.ext}"
                      class="main"
                      alt={image.alt}
                    />
                  </picture>
                </div>
              </div>
            </div>
            <div class="swiper-lazy-preloader swiper-lazy-preloader-white" />
          </SwiperSlide>
        {/each}
      </Swiper>
    </div>
  </div>
</section>

<style>
  section {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    color: var(--secondary);
  }
  .background {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    background: var(--primary);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }
  .background img {
    width: 100vw;
    max-width: 100%;
    min-height: 100vh;
    object-fit: cover;
    object-position: center center;
  }
  @media only screen and (max-width: 900px) {
  }
  .tagline {
    position: absolute;
    bottom: 0px;
    background: var(--bg);
    width: 100vw;
    max-width: 100%;
    margin: 0;
    z-index: 2;
  }
  p {
    max-width: var(--max-width);
    margin: 0 auto;
    font-size: calc(16px + 1vw);
    text-align: left;
    padding: 1em;
    font-weight: 200;
    line-height: 1.1;
  }
</style>
